/** @format */

* {
  margin: 0;
  box-sizing: inherit;
  font-family: inherit;
}

:root {
  font-size: 14px;
}

body {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  color: #01010b;
}

.page-enter {
  opacity: 0;
  transform-origin: center;
}
.page-enter-active {
  opacity: 1;
  transform-origin: center;
  transition: opacity 300ms ease-in, transform 250ms;
}
.page-exit {
  opacity: 1;
  transform-origin: center;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in, transform 250ms;
  transform-origin: center;
}
